import React from "react";
import { Link } from "gatsby";
import { Layout, Menu, Tag } from "antd";
import { BookOutlined } from "@ant-design/icons";

const { Sider } = Layout;

export default class GuideSider extends React.Component {
  render() {
    return (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <Link to={`/guide/`}>
          <h2 style={{ textAlign: "center", marginTop: 20 }}>
            <BookOutlined />
            Guide
          </h2>
        </Link>
        <div>
          <Menu theme="light">
            <Menu.Item key="0">
              <Link to="/guide">Home</Link>
            </Menu.Item>
            <Menu.Item key="1">
              <Link to="/guide-content/account-help">Account</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/guide-content/operation-guide">Operation</Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/guide-content/dayviewer-pro">DayViewer Pro</Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/guide-content/dayviewer-team">DayViewer Team</Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/guide-content/mobile-version">Phone &amp; Tablet</Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link to="/guide-content/tips">Cool Tips</Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link to="/guide-content/tutorial">
                Tutorials <Tag color="green">Recommended</Tag>
              </Link>
            </Menu.Item>
          </Menu>
        </div>
      </Sider>
    );
  }
}
