import React from "react";
import { Layout, Row, Col, Collapse } from "antd";
import { Helmet } from "react-helmet";
import Header from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import GuideSider from "../../components/guide/guide-sider";

import css from "../../styles/guide.css";

const Panel = Collapse.Panel;

class TipsGuide extends React.Component {
  render() {
    return (
      <div>
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="DayViewer Planner Guide - Tips. Some features that are present in DayViewer that makes life easier."
            />
            <title>DayViewer Online Planner Guide - Tips</title>
          </Helmet>
          <Header />
          <Layout style={{ minHeight: "100vh" }}>
            <GuideSider />
            <Layout className="guide-body">
              <h1>DayViewer Mobile Version</h1>
              <Row>
                <Col>
                  <Collapse>
                    <Panel header="Quick Entries" key="1">
                      <p>
                        Quick Entries are designed for fast entry creation -
                        they are at the top of most sections / views where
                        entries are created.
                      </p>
                    </Panel>
                    <Panel header="Swipe Features" key="2">
                      <p>
                        Swiping the DayViewer header left or right will open /
                        close the side menu.
                      </p>

                      <p>
                        Swiping the Calendar header left or right will change
                        the date range (eg changes the month in Month View)
                      </p>
                    </Panel>
                    <Panel header="Expand Calendar" key="3">
                      <p>
                        The Expand Calendar feature with DayViewer Pro allows a
                        full screen version of the calendar, which expands
                        vertically in the monthly view, allowing more entries to
                        be seen at a glance. On Phones, the calendar also
                        expands horizontally across so the calendar becomes more
                        readable in portrait mode.
                      </p>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Layout>
          </Layout>
          <CommonFooter />
        </Layout>
      </div>
    );
  }
}

export default TipsGuide;
